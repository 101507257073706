
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
import Dropzone from "@/components/Dropzone.vue";
import { mixins } from "vue-class-component";
import VModel from "@/mixins/VModel.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

@Component({
    name: "PdfManager",
    components: {
        Dropzone,
        VuePdfEmbed,
    },
})
export default class PdfManager extends mixins(VModel) {
    @Prop() readonly!: boolean;
    @Prop({ default: true }) showActions!: boolean;
    @Prop({
        required: true,
    })
    path!: string;
    @Prop() extensionAccept!: string[];

    $refs!: any;
    document: any = {
        file: "",
        type: "",
    };
    showPdfDoc = false;
    loaded = false;
    scale = 1;

    // @ts-ignore;
    apiUrl: string = <string>process.env.VUE_APP_API_URL.replace("/1.0", "") + "/";

    // is here for reload pdf compnent on changing tab for example
    @Watch("document", { deep: true })
    onUpdateDocumentInformations() {
        this.showPdfDoc = false;
        setTimeout(() => {
            this.showPdfDoc = true;
        }, 100);
    }

    get noDocument() {
        return !this.path && this.readonly;
    }

    async save(doc: any) {
        this.document = { file: this.prepareDocumentPath(doc), type: this.checkType(doc) };
        this.$emit("add", doc);
    }

    async editPdf() {
        this.$refs.dropzone.focusInput();
    }

    removePdf() {
        this.document = { file: "", type: "" };
        this.showPdfDoc = false;
        this.$emit("delete");
    }

    downloadCV() {
        this.$refs.link.click();
    }

    checkType(doc: string) {
        if (doc.indexOf(".pdf") !== -1 || doc.indexOf("application/pdf") !== -1) return "pdf";
        else if (
            doc.indexOf(".jpg") !== -1 ||
            doc.indexOf(".jpeg") !== -1 ||
            doc.indexOf(".png") !== -1 ||
            doc.indexOf(".webp") !== -1 ||
            doc.indexOf("image/") !== -1
        )
            return "img";
    }

    prepareDocumentPath(doc: string) {
        // base64
        if (doc.startsWith("data:")) return doc;
        // server path
        else if (doc.startsWith("static/")) return this.apiUrl + doc;
    }

    @Watch("path")
    init() {
        if (!this.path) return;
        this.document = { file: this.prepareDocumentPath(this.path), type: this.checkType(this.path) };
        this.showPdfDoc = true;
    }

    beforeMount() {
        this.init();
    }

    zoom(action: string) {
        if (action === "+") this.scale += 0.5;
        else if (action === "-" && this.scale > 1) this.scale -= 0.5;
        if (this.document.type === "pdf") {
            const canvas = document.querySelector(".PdfManager canvas");
            if (canvas) {
                // @ts-ignore
                canvas.style.transform = `scale(${this.scale})`;
            }
        } else if (this.document.type === "img") {
            const img = document.querySelector(".PdfManager .imageDocument");
            if (img) {
                // @ts-ignore
                img.style.transform = `scale(${this.scale})`;
            }
        }
    }
}
