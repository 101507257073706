import { ECandidateOfferStatus, ROUTES } from "@/data";
import { DropdownCollection } from "@/dsComponents/Table/types";
import router from "@/router";
import { IJobOffersWorker } from "@/types";

export default function useJobOfferCandidateActions(
    candidate: IJobOffersWorker,
    updateFunction: Function,
    openModalFunction?: Function
): DropdownCollection[] {
    const items: DropdownCollection[] = [
        {
            type: "item",
            item: {
                iconLeft: "user-search",
                label: "Détails",
                onClick: (item) => router.push({ name: ROUTES.APP.ATS.CANDIDATE._ROOT, params: { candidateId: item._id } }),
            },
        },
        { type: "title", title: { icon: "status-change", label: "Déplacer" } },
        {
            type: "item",
            key: ECandidateOfferStatus.TOSTUDY,
            item: {
                iconLeft: "arrow-narrow-right",
                label: "À l'étude",
                onClick: (item: IJobOffersWorker) => updateFunction(item, ECandidateOfferStatus.TOSTUDY),
            },
        },
        {
            type: "item",
            key: ECandidateOfferStatus.PROPOSED,
            item: {
                iconLeft: "arrow-narrow-right",
                label: "Proposé au client",
                onClick: (item: IJobOffersWorker) => updateFunction(item, ECandidateOfferStatus.PROPOSED),
            },
        },
        {
            type: "item",
            key: ECandidateOfferStatus.RETAINED,
            item: {
                iconLeft: "arrow-narrow-right",
                label: "Retenu par le client",
                onClick: (item: IJobOffersWorker) => updateFunction(item, ECandidateOfferStatus.RETAINED),
            },
        },
        {
            type: "item",
            key: ECandidateOfferStatus.NOTRETAINED,
            item: {
                iconLeft: "arrow-narrow-right",
                label: "Non retenu",
                onClick: (item: IJobOffersWorker) => updateFunction(item, ECandidateOfferStatus.NOTRETAINED),
            },
        },
        { type: "separator" },
        { type: "title", title: { icon: "user-square-rounded", label: "Candidat" } },
        {
            type: "item",
            item: {
                iconLeft: "file",
                label: "Voir le cv",
                onClick: (item: IJobOffersWorker) => {
                    if (openModalFunction) openModalFunction(item);
                },
            },
        },
    ];

    return items.filter((item) => item.key !== candidate.statusInJobOffer);
}
